.google-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 4px;
    background-color: #000000;
    color: white;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.google-button:hover {
    background-color: #222222;
}

.google-button:active {
    background-color: #000;
}

.google-icon {
    width: 18px;
    height: 18px;
    margin-right: 10px;
}

