.auth-layout__content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-layout__image {
    text-align: center;
    padding: 20px 25px;
}

.auth-layout__form {
    max-width: 90vw;
    min-width: 10vw;
    background-color: white;
    padding: 20px 25px;
    border-radius: 8px;
    border: 1px solid #d8d8da;
}

h2 {
    text-align: center;
    color: #083971;
}