.login-form__forgot-wrapper {
    width: 100%;
    padding: 10px;
    text-align: right;
}

.login-form__forgot {
    color: #1890ff;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
}

.login-form__oauth {
    width: 100%;
    padding: 10px;
    text-align: center;
}

.login-form__register {
    width: 100%;
    padding: 10px;
    text-align: center;
}