.Visits__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0 20px;
}

.Visits__container__group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0 20px;
}

.Visits__container__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0 20px;
    background-color: rgba($color: #EEEEEE, $alpha: 1.0);
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.1);
}

.Visits__container__item h5 {
    margin: 0px;
    padding: 5px;
}

.Visits__container__item p {
    margin: 0px;
    padding: 5px;
}