:root {
    --primary-color: #2D3436;
    --secondary-color: white;
    --terciary-color: #3b4346;
    --accent-color: #0E6EB8;
    --outline-color-strong: #96999A;
    --outline-color-weak: #BCBEBE;
    --bold-weight: 700;
}

.short-link-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    &__link {
        margin-bottom: 0.5rem;
        font-size: 1.25rem;
    }

    &__copy-button {
        margin-bottom: 0.5rem;
        padding: 0.5rem 1rem;
        font-size: 1rem;
        font-weight: 600;
        border: none;
        border-radius: 0.5rem;
        cursor: pointer;
        transition: background-color 0.2s ease-in-out;
    }

    &__error {
        margin-bottom: 0.5rem;
        font-size: 1rem;
        font-weight: 600;
    }
}
.short-link-display__link-text {
    padding-top: 0.4rem;
    margin-bottom: 0.2rem;
}

.short-link-display__link {
    border-radius: 0.5rem;
    background-color: #e1b051;
}

.short-link-display__link-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    border: none;
    background-color: #e1b051;
}

.agrupacion {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
}

.titulo {
    padding-top: 0.5rem;
    font-weight: 600;
}