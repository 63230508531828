.full-layout {
  display: flex;
  flex-direction: column;
  min-height: 90vh;
  width: 100%;
}

.full-layout__content {
  flex: 1;
  overflow: auto;
}

.full-layout__inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}