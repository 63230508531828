:root {
    --primary-color: #2D3436;
    --secondary-color: white;
    --terciary-color: #3b4346;
    --accent-color: #0E6EB8;
    --outline-color-strong: #96999A;
    --outline-color-weak: #BCBEBE;
    --bold-weight: 700;
}

.flex-container {
    display: flex;
}
.flex-spaced {
    justify-content: space-between;
}

.text-inputs {
    width: 498px;
    margin: 0.5em;
}

.text-inputs > * {
    margin: 0.75em;
    height: 40px;
    background-color: #EEEEEE;
    border-radius: 4px;
    border: 1px solid #DDDDDD;
    padding: 0 1em;
    color: var(--primary-color);
}

.dashboard__container__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    height: 100%;
    width: 100%;
    padding: 20px 20px;
}