.form__button-wrapper {
    padding: 10px;
}

.form__button {
    width: 100%;
    background-color: #6c8bc4;
    color: white;
    font-size: 16px;
    font-weight: 500;
    border: none;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.form__button:hover {
    background-color: #083971;
}

.form__button:active {
    background-color: #11193d;
}

.form__button:disabled {
    background-color: grey;
    color: black;
    cursor: not-allowed;
}