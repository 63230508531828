.DataPagination__container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
}

.DataPagination__container__page {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.page-item.active .page-link{
    background-color : #083971 !important;
    border-color: #083971 !important;;
    color: #FFF !important;
}
.page-link{
    color: #083971 !important;
}