.navbar__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: white;
    height: 60px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    color: #11193d;
}

.navbar__logo-container {
    width: 80px;
    font-size: 1.5rem;
    font-weight: 700;
    color: #e1b051;
    text-decoration: none;
}

.navbar__link-container {
    display: flex;
    align-items: center;
    width: 100%;
}

.navbar__link-container a {
    color: #11193d;
    cursor: pointer;
}

.navbar__link-container__left {
    display: flex;
    align-items: center;
    width: 100%;
}

.navbar__link-container__left a {
    margin-left: 20px;
    padding: 7px 15px;
    text-decoration: none;
}

.navbar__link-container__left a:hover {
    background-color: #f8f8f8;
    text-decoration: none;
    color: #11193d;
}

.navbar__link-container__right a {
    margin-left: 20px;
    padding: 7px 15px;
    border: 1px solid  #e1b051;
    border-radius: 30px;
}

.navbar__link-container__right a:hover {
    background-color: #e1b051;
    color: white;
    text-decoration: none;
}

.navbar__link-container-mobile {
    display: none;
}

.navbar__link-container-mobile__hamburguer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
    cursor: pointer;
}

.navbar__link-container-mobile__hamburguer div {
    width: 30px;
    height: 2px;
    background-color: #11193d;
}

.mobile-hide {
    visibility: hidden!important;
    opacity: 0!important;
    transition: visibility 0.5s, opacity 0.5s ease-in-out!important;
}

.navbar__link-container-mobile__menu {
    display: flex;
    visibility: visible;
    opacity: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: white;
    z-index: 9999999;
    transition: visibility 0s, opacity 0.5s ease-in-out;
    cursor: pointer;
}

.navbar__link-container-mobile__menu__close {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 40px;
    width: 40px;
    font-size: 1.5rem;
    color: #11193d;
    cursor: pointer;
}

.navbar__link-container-mobile__menu__close:hover {
    color: #e1b051;
}

.navbar__link-container-mobile__menu__close__x {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 2px;
    background-color: #11193d;
}

@media screen and (max-width: 768px) {

    .navbar__link-container {
        display: none;
    }

    .navbar__link-container__left {
        display: none;
    }

    .navbar__link-container__right {
        display: none;
    }

    .navbar__link-container-mobile {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
    }

    .navbar__logo-container-mobile {
        width: 100%;
        text-align: center;
        font-size: 3.5rem;
        font-weight: 700;
        color: #e1b051;
        text-decoration: none;
    }

    .navbar__link-container-mobile__menu a {
        margin: 20px 0;
        font-size: 1.5rem;
        font-weight: 700;
        color: #11193d;
        text-decoration: none;
    }

    .navbar__link-container-mobile__menu a:hover {
        color: #e1b051;
    }

    .navbar__link-container-mobile__menu a:active {
        color: #e1b051;
    }


}
    