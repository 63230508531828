.adWall {
    min-height: 50vh;
}

.redirect__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    height: calc(100vh - 100px);
}

.redirect__title {
    margin-bottom: 2rem;
    margin-top: 2rem;
    color: #083971;
}

.redirect__timer__container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e1b051;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    padding: 30px;
    margin-bottom: 2rem;
}

.redirect__timer__container p {
    font-size: 1.6rem;
    font-weight: 400;
    margin: 0;
}

.redirect__ad-wall {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90vw;
    height: 100%;
    min-height: 30vh;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 1rem;
}