.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0 20px;
}

.home__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
    width: 100vw;
    padding: 0 20px;
}

.home__container__title {
    font-size: 3rem!important;
}

.home__container__subtitle {
    font-size: 1rem;
    text-align: center;
}

.home__container__links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    padding: 0 20px;
}

.home__container__links a {
    margin: 0 10px;
    border: 1px solid #e1b051;
    border-radius: 30px;
    padding: 10px 20px;
}

.home__container__links a:hover {
    background-color: #e1b051;
    color: #fff;
}

.home__container__links a:active {
    background-color: #e1b051;
    color: #fff;
}

.home__container__left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 40%;
    min-width: 400px;
    padding: 50px 20px;
}

.home__container__right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
    width: 50%;
    min-width: 400px;
    padding: 50px 20px;
}