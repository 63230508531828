.texto {
    height: 10%;
    text-align: center;
    padding-top: 0.2%;
}
.divImagen {
    height: 90%;
    text-align: center;
    padding-top: 0.5%;
}

.imagen {
    height: 90%;
    width: 90%;
    object-fit: contain;
}

.contain {
    height: calc(100vh - 60px);
}