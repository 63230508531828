.form-element {
    padding: 9px;
    width: 100%;
}

.form-element__container {
    width: 100%;
}

.form-element__label {
    display: block;
    font-size: 18px;
}

.form-element__input {
    width: 100%;
    padding: 1px;
    font-size: 16px;
}

@keyframes shake {
    0% {
        margin-left: 0rem;
    }

    25% {
        margin-left: 0.5rem;
    }

    75% {
        margin-left: -0.5rem;
    }

    100% {
        margin-left: 0rem;
    }
}

.form-element__input__error {
    border: 1px solid #f49696 !important;
    animation: shake 0.2s ease-in-out 0s 2;
}

.form-element__error {
    margin-top: 10px;
    width: 100%;
    background-color: #f49696;
    padding: 5px 0px;
    border-radius: 10px;
    text-align: center;
}

.form-element__error__message {
    color: rgb(119, 15, 15);
}

.line-input {
    position: relative;
}

.line-input input {
    padding: 0.375rem 0.75rem;
    border: none;
    outline: none;
    background: #fff;
    border-bottom: 2px solid #ccc;
    border-radius: 0;
    box-shadow: none;
    text-align: center;
}

.line-input input :focus {
    border-bottom: 2px solid #fff;
}

.line-input input :focus+.focus-line {
    width: 100%;
}

.line-input .focus-line {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: e;
    height: 2px;
    background: #2196f3;
    transform: translatex(-50%);
    transition: 0.3s all ease;
    -webkit-transform: translatex(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translatex(-50%);
    -o-transform: translatex(-50%);
    -webkit-transition: 0.3s all ease;
    -moz-transition: 0.3s all ease;
    -ms-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
}