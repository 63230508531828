.register-form__login {
    width: 100%;
    padding: 10px;
    text-align: center;
}

.register-form__oauth {
    width: 100%;
    padding: 10px;
    text-align: center;
}