.image {
    width: 85%;
    height: 85%;
}

.line-input {
    position: relative;
}
.line-input input {
    padding: 0.375rem 0.75rem;
    border: none;
    outline: none;
    background: #fff;
    border-bottom: 2px solid #ccc;
    border-radius: 0;
    box-shadow: none;
    text-align: center;
}

.line-input input :focus {
    border-bottom: 2px solid #fff;
}
.line-input input :focus + .focus-line {
    width: 100%;
}

.line-input .focus-line {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: e;
    height: 2px;
    background: #2196f3;
    transform: translatex(-50%);
    transition: 0.3s all ease;
    -webkit-transform: translatex(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translatex(-50%);
    -o-transform: translatex(-50%);
    -webkit-transition: 0.3s all ease;
    -moz-transition: 0.3s all ease;
    -ms-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
}