.DataList__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    height: 100%;
    padding: 0 20px;
}

.DataList__empty {
    padding: 20px;
    text-align: center;
}
.DataList__header__short {
    width: 80%;
}
.DataList__header__options {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 20px;
}