@import url('assets/fonts/chillax/css/chillax.css');

body {
    font-family: 'Chillax-Medium';
    height: 100vh;
}

#root {
    height: 100vh;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Chillax-Semibold';
}

input {
    font-family: 'Chillax-Regular';
}

a {
    text-decoration: none;
    color : #6c8bc4;
}

a:hover {
    text-decoration: none;
    color : #083971;
}

a:active {
    text-decoration: none;
    color : #11193d;
}

a:visited {
    text-decoration: none;
    color : #6c8bc4;
}