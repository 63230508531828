.DataRow__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    height: 100%;
    width: 100%;
    padding: 0 20px;
}

.DataRow__container__short {
    width: 80%;
}

.DataRow__container__short p {
    margin: 0;
    max-width: 50vw;
}

.DataRow__options {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    padding: 0 20px;
}

.DataRow__options button {
    border: none;
    background-color: #FFF;
}

.DataRow__container__short__text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.DataRow__container__short__link {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.info-db {
    display: grid;
    border-bottom: 1px solid var(--outline-color-weak);
    font-size: 16px;
    cursor: default;
    min-height: 70px;
}

.info-db:hover {
    border: 1px solid var(--outline-color-strong);
    border-left: none;
    border-right: none;
}

.info-db.active {
    border: 1px solid var(--accent-color);
    border-left: none;
    border-right: none;
}

.info-db > * {
    margin: auto;
}